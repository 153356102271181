import {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import {NavLink, Outlet} from "react-router-dom";
import style from './adminka.module.css'
import {ReactComponent as Pen} from "../../assets/svg/Edit.svg";
import {ReactComponent as People} from "../../assets/svg/Users.svg";
import {ReactComponent as LogOut} from "../../assets/svg/LogOut.svg";
const Adminka = () => {
    const {setAuth} = useContext(AuthContext);
    function logOut(){
        setAuth(()=>false)
        localStorage.removeItem('token')
    }

  return (
      <div className={style.section}>
          <nav className={style.admin_nav}>
              <span className={style.admin_logo}>Cranerecord</span>
              <ul className={style.admin_list}>
                  <li className={style.admin_item}>
                      <NavLink  className='admin_link' to={'/'}>
                         <Pen/>
                          <span className='admin_link_text'>Обращения с сайта</span>
                      </NavLink>
                  </li>
                  <li className={style.admin_item}>
                      <NavLink   className='admin_link' to={'users'}>
                          <People/>
                          <span className='admin_link_text'>Пользователи</span>
                      </NavLink>
                  </li>
              </ul>
              <div className="btn_wrapper">
                  <LogOut/>
                  <button className={'btn'} onClick={()=>logOut()}>
                      <span className="btn_text">Выйти</span>
                  </button>
              </div>
          </nav>
        <Outlet/>
    </div>
  )
}

export default Adminka