import './modal.css'
import {useForm} from "react-hook-form";
import api from '../../api'
import {useState} from "react";
const Modal = ({getUsers,isModal,setModal}) => {
    const [text,setText] = useState('')

    const {register,
        handleSubmit,
        clearErrors,
        setError,
        formState:{ errors },
    } = useForm()
    const onSub =  async (data,event) => {
        event.preventDefault()
        try{
            const resp = await api.post(`/api/v1/register`,{
                name:data.name.trim(),
                email:data.email.trim(),
                password:data.password.trim(),
                phone:data.phone.trim()
            })
            console.log(resp)
            if(resp.status === 201) {
                setText(`пользователь ${data.email.trim()}  зарегистрирован`)
                console.log(errors)
                getUsers()
            } else setText('операция прервана')
        } catch (e) {
            console.log(e.message,e)
            setText('что-то пошло не так')
        }
    }
    return (
      <form className={'form'} onSubmit={handleSubmit(onSub)}>
          <div className="form_black-block">
              <button className='userAddBtn' style={isModal &&  {position:'absolute',right:'1%',top:'0',padding:'1rem'}} onClick={()=>setModal(!isModal)}>
              {!isModal?<span className="form_title">Авторизация</span>:<span>X</span>}
                </button>
          </div>
          <div className={'form_wrap'}>
              <div className="input_wrap">
                  <label className={'form_elem'} htmlFor='email'>{errors.email? errors.email.message : 'email'}</label>
                  <input className={'form_input'} id='email'   type="email"
                         {...register('email',
                             {
                                 onChange:(i=>{
                                     if(!/.+@.+\..+/i.test(i.target.value)){
                                         setError(
                                             'email',
                                             {
                                                 type:'custom',
                                                 message:'please enter valid email',
                                             })
                                     } else { clearErrors('email')}
                                 }),
                                 required:{
                                     value:true,
                                     message:'это поле обязательно к заполнению'
                                 }
                  })}/>
              </div>
              <div className="input_wrap">
                  <label className={'form_elem'} htmlFor='password'>{errors.password? errors.password.message : 'password'}</label>
                  <input className={'form_input'}  id='password'    type="text"
                         {...register('password',{
                             onChange:(i=>{
                                 if(i.target.value.length <= 7){
                                     setError(
                                         'password',
                                         {
                                             type:'password to low',
                                             message:'пароль слишком короткий'
                                         })
                                 } else { clearErrors('password')}
                             }),
                         required:{
                             value:true,
                             message:'это поле обязательно к заполнению'
                          }
                         })}/>
              </div>
              <div className="input_wrap">
                  <label className={'form_elem'} htmlFor='name'>{errors.name? errors.name.message : 'name'}</label>
                  <input className={'form_input'} id='name'   type="text"
                         {...register('name',
                             {
                                 required:{
                                     value:true,
                                     message:'это поле обязательно к заполнению'
                                 }
                  })}/>
              </div>
              <div className="input_wrap">
                  <label className={'form_elem'} htmlFor='phone'>{errors.phone? errors.phone.message : 'phone'}</label>
                  <input className={'form_input'} id='phone'   type="tel"
                         {...register('phone',
                             {
                                 onChange:(i=>{
                                     if(!/^(\+?[78])?\d{10}$/.test(i.target.value)){
                                         setError(
                                             'phone',
                                             {
                                                 type:'custom',
                                                 message:'введите корректный номер телефона',
                                             })
                                     } else { clearErrors('phone')}
                                 }),
                                 required:{
                                     value:true,
                                     message:'это поле обязательно к заполнению'
                                 }
                  })}/>
              </div>
              {text? <span style={{color:'red'}}>{text}</span>: null}
              <button  className={'btn_auth'} disabled={(!!errors?.email & !!errors?.password) || false} type={"submit"}>зарегистрировать</button>
          </div>
      </form>
  )
}

export default Modal