import {useCallback, useEffect, useState} from "react";
import api from "../../api";
import {dateConvertor} from '../../scripts/dataConverter'
const Appeals = () => {
    const [appeals,setAppearl] = useState([]);

    const getUsers = useCallback(async ()=>{
        const token = localStorage.getItem('token')
        const headers = { Authorization: `Bearer ${token}` };
        
        try{
            const resp = await api.get(`/admin/v1/registration-request?page=0&size=50`,{headers})
            if(resp.status === 200) {
                setAppearl(resp.data.content)
            }
        }catch (e) {
            console.log(e.message)
        }
    },[])

    useEffect(() => {
        getUsers().catch(console.error)
    },[])

    const arr = appeals.toReversed()
    return (
      <div className={'container'}>
          <div className="main_info">
              <span className="main-title">Обращения с сайта</span>
          </div>
          <main className={'main'}>
              <ul className="table_list">
                  <li className="table_item table_title">
                      <div className="table_item-block table_item-block-title brlf">Имя</div>
                      <div className="table_item-block table_item-block-title brlf">дата отправки</div>
                      <div className="table_item-block brlf">Телефон</div>
                      <div className="table_item-block brlf">Email</div>
                      <div className="table_item-block brlf">место отправки</div>
                  </li>
                  {arr?.map(i=>{
                     let len = arr.length++
                      return (<li className="table_item" key={len}>
                          <div className="table_item-block">{i.name ? i.name : '-'}</div>
                          <div className="table_item-block">{i.createDate ? (dateConvertor(i.createDate)) : '-'}</div>
                          <div className="table_item-block">{i.phone ? i.phone : '-'}</div>
                          <div className="table_item-block">{i.email? i.email:'-'}</div>
                          <div className="table_item-block">{i.source? i.source:'-'}</div>
                      </li>)
                  })}
              </ul>
          </main>
      </div>
  )
}

export default Appeals