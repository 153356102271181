export const dateConvertor =(d) => {
    const dat = new Date(d)
    const data = new Intl.DateTimeFormat('ru-Ru',{
        day: "numeric",
        month: "numeric",
        year: "2-digit",
        hour:'numeric',
        minute:'numeric',
        timeZone:'Europe/Moscow'
    })
    return new Date(d).toLocaleString("ru-Ru", {timeZone: "Europe/Moscow"})
    // return data.format(dat)
}