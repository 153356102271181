import AuthContext from "../../context/AuthContext";
import {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import api from '../../api'
import style from './Auth.module.css'
import { decodeToken  } from "react-jwt";
const Auth = () => {
    const {setAuth} = useContext(AuthContext);
    const [authText,setAuthText] = useState('')

    useEffect(()=>{
        if(!!localStorage.getItem('token')) {
            setAuth(true)
        }
    })

    const {register,
        handleSubmit,
        clearErrors,
        setError,
        formState:{ errors },
    } = useForm()
    const onSub =  async (data) => {
        try{
            const resp = await api.post(`api/v1/login`,{
                email:data.email.trim(),
                password:data.password.trim()
            })
             if(resp.status === 201) {
                const decodedToken = decodeToken(resp.data.access_token)
                if(decodedToken.roles[0] === "ROLE_ADMIN") {
                    setAuth(true)
                    localStorage.setItem('token', resp.data.access_token);
                }else {
                    setAuthText('Вы не являетесь администратором')
                    localStorage.removeItem('token')
                }
            }
        }catch (e) {
            console.log(e.message)
            setAuthText('авторизация не удалась')
        }
        console.log(authText)
    }

    return (
        <div className={style.section}>
            <form className={'form'} onSubmit={handleSubmit(onSub)}>
                <div className="form_black-block">
                    <span className="form_title">Авторизация</span>
                </div>
                <div className={'form_wrap'}>
                    <div className="input_wrap">
                        <label className={'form_elem'} htmlFor='email'>{errors.email? errors.email.message : 'email'}</label>
                        <input className={'form_input'} id='email'   type="email"
                               {...register('email',
                                   {
                                       onChange:(i=>{
                                           if(!/.+@.+\..+/i.test(i.target.value)){
                                               setError(
                                                   'email',
                                                   {
                                                       type:'custom',
                                                       message:'please enter valid email',
                                                   })
                                           } else { clearErrors('email')}
                                       }),
                                       required:{
                                           value:true,
                                           message:'это поле обязательно к заполнению'
                                       }
                                   })}/>
                    </div>
                    <div className="input_wrap">
                        <label className={'form_elem'} htmlFor='password'>{errors.password? errors.password.message : 'password'}</label>
                        <input className={'form_input'}  id='password'    type="text"
                               {...register('password',{
                                   onChange:(i=>{
                                       if(i.target.value.length <= 7){
                                           setError(
                                               'password',
                                               {
                                                   type:'password to low',
                                                   message:'пароль слишком короткий'
                                               })
                                       } else { clearErrors('password')}
                                   }),
                                   required:{
                                       value:true,
                                       message:'это поле обязательно к заполнению'
                                   }
                               })}/>
                    </div>
                    <button className={'btn_auth'} disabled={(!!errors?.email & !!errors?.password) || false} type={"submit"}
                            >Войти</button>
                    {authText? <span style={{color:'red'}}>{authText}</span> :null}
                </div>
            </form>
        </div>
    )
}

export default Auth