import { Route, Routes} from "react-router-dom";
import Adminka from "../Adminka/Adminka";
import NotFound from "../notFoundComponent/NotFound";
import Users from '../Users/Users'
import Appeals from "../Appeals/Appeals";

const Navigation = () =>{
    return (
            <Routes>
                <Route path={'/'} element={<Adminka/>} >
                    <Route index element={<Appeals/>}></Route>
                    <Route path={'Users'} element={<Users/>}></Route>
                </Route>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
    )
}
export default Navigation