import React, {useContext} from "react";
import './App.css'
import Auth from "./components/Auth/Auth";
import AuthContext from "./context/AuthContext";
import Navigation from "./components/navigation/navigation";
function App() {
  const {auth} = useContext(AuthContext);
  return (
      (auth ?  <Navigation/> : <Auth/> )
  );
}

export default App;
