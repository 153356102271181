import {useCallback, useEffect, useState} from "react";
import api from '../../api'
import {ReactComponent as Plus} from "../../assets/svg/Plus.svg";
import Modal from "../ModalForm/Modal";
import {dateConvertor} from '../../scripts/dataConverter'
const Users = () => {
    const [users,setUsers] = useState([]);
    const [isModal,setModal] = useState(false)

    const getUsers = useCallback(async ()=>{
        
        const token = localStorage.getItem('token')
        const headers = { Authorization: `Bearer ${token}`};
        try{
            const resp = await api.get(`/admin/v1/users?page=0&size=50`, {headers})
            if(resp.status === 200) {
                setUsers(resp.data.content)
            }
        }catch (e) {
            console.log(e.message)
        }
    },[])

    useEffect(() => {
        getUsers().catch(console.error)
    },[])
    return (
        <div className={'container'}>
            <div className="main_info">
                <span className="main-title">Пользователи</span>
                <button className='userAddBtn' onClick={()=>setModal(!isModal)}>
                    <><Plus/><span>Добавить пользователя</span></>
                </button>
            </div>
      <main className='main'>
          <ul className="table_list">
              <li className="table_item table_title">
                  <div className="table_item-block table_item-block-title brlf">имя</div>
                  <div className="table_item-block table_item-block-title brlf">телефон</div>
                  <div className="table_item-block table_item-block-title brlf">Был последний раз</div>
                  <div className="table_item-block brlf">Количество звонков
                      загружено</div>
                  <div className="table_item-block brlf">Количество звонков
                      распознано</div>
                  <div className="table_item-block brlf">Email</div>
              </li>
              {users?.map(i=>{
                  let k = users.length++
                  return (<li className="table_item" key={k}>
                      <div className="table_item-block">{i.name ? i.name : '-'}</div>
                      <div className="table_item-block">{i.phone ? i.phone : '-'}</div>
                      <div className="table_item-block">{ i.lastVisitDate ? (dateConvertor(i.lastVisitDate)) : '-'}</div>
                      <div className="table_item-block">{i.uploadCalls ? i.uploadCalls : '-'}</div>
                      <div className="table_item-block">{i.doneCalls? i.doneCalls:'-'}</div>
                      <div className="table_item-block">{i.email? i.email:'-'} </div>
                  </li>)
              })}
          </ul>
          {isModal ? <Modal setModal={setModal} isModal={isModal} getUsers={getUsers}/> : null}
            </main>
        </div>
  )
}
export default Users