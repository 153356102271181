import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthContext from "./context/AuthContext";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
function Main(){
    const [auth,setAuth]=useState(false)
    return (
        <BrowserRouter>
            <AuthContext.Provider value={{auth,setAuth}}>
                <App />
            </AuthContext.Provider>
        </BrowserRouter>
    )}
root.render(
    <Main/>
);